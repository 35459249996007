<template>
	<div class="columns is-centered">
		<div class="column is-3">
			<form class="form-horizontal" >
				<fieldset>
					<!-- Form Name -->
					<legend class="has-text-right">Login</legend>

					<!-- Text input-->
					<div class="field">
						<label class="label" for="textinput-0">Email</label>
						<div class="control">
							<input id="textinput-0" v-model="user.email" 
								name="textinput-0" type="email" 
								placeholder="" class="input" >
							<!-- <p class="help">Adresa email</p> -->
						</div>
					</div>

					<!-- Password input-->
					<div class="field">
						<label class="label" for="passwordinput-0">Password</label>
						<div class="control">
							<input id="passwordinput-0" v-model="user.password" 
								name="passwordinput-0" type="password" 
								placeholder="" class="input" >
							<!-- <p class="help">Passwordi juaj</p> -->
						</div>
					</div>
					<div class="field border-top">
						<router-link to="/register" class="button is-small is-pulled-left">
							<i class="fa-solid fa-chevron-left mr-2 has-text-grey"></i> 
							Regjistrohu</router-link>
						<button class="button is-small is-pulled-right is-info is-outlined"
							@click.prevent="login">Login</button>
					</div>
				</fieldset>
			</form></div>
	</div>
</template>

<script>
	import { storeToRefs } from 'pinia'
	import { useUserStore } from '@/stores/user'
	import Api from '@/services/Api'
	import permissionMixin from '@/mixins/permissions-mixin.js'
	import { useConfigurationsStore } from '@/stores/configurations'
	var CryptoJS = require("crypto-js");

	export default{
		mixins: [permissionMixin],
		data(){
			return {
				user: {
					email: '',
					password: ''
				},
				
				user_store: storeToRefs(useUserStore())
			}
		},
		created(){
			this.$document.title = "Login"
		},
		methods:{
			login(){
				Api(false).post('user/login', this.user)
				.then(async (r) => {
					if(!r.data.token){
						// Toaster for incorrect credentials
						this.$toast.error('Gabim! Kontrolloni email dhe password.')
					}
					localStorage.user = JSON.stringify(r.data.user)
					this.user_store.user = r.data.user
					localStorage.token = r.data.token
					localStorage.user_steps = JSON.stringify(r.data.user_steps.map(x => {return x.step_id}))

					// Get permissions list
					await Api(true).post('permissions/list').then(res => {
						this.permissions_modules = res.data
						localStorage.user_roles = JSON.stringify(res.data)
					})

					// Save permissions to localstorage
					if(r.data.user_permissions){
						var a = CryptoJS.AES.encrypt(JSON.stringify(r.data.user_permissions), localStorage.token).toString();
						localStorage.permissions = a
					}

					this.$router.push({ path: '/home'})
					this.user_store.is_loggedin = true

					// get general configurations 
					useConfigurationsStore().getConfigs()

					// Get user notifications
					useUserStore().get_user_notifications(r.data.user)	

					// setTimeout(() => {
					// 	window.location.reload();
					// }, 1000);
				})
			}
		}
	}
</script>

<style scoped>

</style>

